import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { css } from '@emotion/css'
import { flexHoriz } from '../../styles';
import * as BlogPosts from '../../types/blog';
import Layout from '../../components/layout';
import { Post, PostItem } from '../../components/postitem';
import { BlogSidebar } from '../../components/blogsidebar';
import { Divider } from 'semantic-ui-react'
import { mq } from '../../styles';

const BLOG_POSTS: BlogPosts.BlogPostMetadata[] = [
    BlogPosts.POST_1,
]

interface BlogProps {
    data: any
}

interface BlogState {
    posts: Post[]
}


class Blog extends React.Component<BlogProps, BlogState> {
    constructor(props: BlogProps) {
        super(props);
    }

    render() {
        return (
            <Layout>
                <div className={flexHoriz}>
                    <div className={items}>
                        {BLOG_POSTS.map(post => (
                            <React.Fragment>
                                <PostItem key={post.slug} post={post} />
                                <Divider className={divider} />
                            </React.Fragment>
                        ))}
                    </div>
                    <BlogSidebar />
                </div>
            </Layout>
        );
    }
}

export default Blog;

////////////////////////////////////////////////////////////////////////////////
// CSS
////////////////////////////////////////////////////////////////////////////////

const divider = css({
    width: "98%"
});

const items = css(mq({
    width: "100%",
    borderLeft: '0px!important',
    borderTop: '0px!important',
    borderBottom: '0px!important',
    borderRightWidth: ['0px!important', '0px!important', '1px!important'],
    borderRightColor: '#3A3D45',
    borderStyle: ['none!important', 'none!important', 'solid!important']
}));

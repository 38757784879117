import React from 'react';
import { Image, Item } from 'semantic-ui-react'
import { css, cx } from '@emotion/css'
import { flexVert, siteBodyText, siteTextColor, flexHoriz } from '../styles';
import { Link } from 'gatsby';
import { BlogPage, blogImage } from './blogui';
import { LinkType } from './generalui';

interface PostItemProps {
    post: Post
}

interface PostItemState {
}

export interface Post {
    title: string,
    author: string,
    date: string,
    slug: string,
    teaser: string,
    tags: string[],
    image: string,
}

export class PostItem extends React.Component<PostItemProps, PostItemState> {
    state: PostItemState = {
    };

    render() {
        const { post } = this.props;
        return (
            <React.Fragment>
                <div key={post.slug} className={flexHoriz}>
                    <div className={blogEntry}>
                        <Image src={'/images/' + post.image} className={blogImage} />
                        <BlogPage.Header text={post.title} link={post.slug} linkType={LinkType.Internal} />
                        <BlogPage.Meta>{post.date} by <b><Link to="/about">{post.author}</Link></b></BlogPage.Meta>
                        <p>
                            {post.teaser}
                        </p>
                        <Link to={post.slug}>Continue Reading ...</Link>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}


////////////////////////////////////////////////////////////////////////////////
//CSS
////////////////////////////////////////////////////////////////////////////////

const blogEntry = cx(siteBodyText, css({
    width: '100%!important',
    paddingRight: '0px!important',
}));
